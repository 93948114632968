function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    );
}

console.log("HI JIMMY");
const box = document.getElementById('store');
console.log(box);
const rect = box.getBoundingClientRect();
console.log(rect);


document.addEventListener('scroll', function () {
  console.log("HI MAX");
    const box = document.getElementById('store');
    const messageText = isInViewport(box) ?
        'The box is visible in the viewport' :
        'The box is not visible in the viewport';

    console.log(messageText);
}, {
    passive: true
});